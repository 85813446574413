import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import PrivateRoute from './utils/PrivateRoute'
import HomePrivateRoute from './utils/HomePrivateRoute'
import { AuthProvider } from './context/AuthContext'
import AuthprivateRoute from './utils/AuthPrivateRoute'
import ScrollToTopArrow from './scroll/ScrollToTopArrow'
import './scroll/ScrollToTopArrow.css'
import PageTransition from './transition/PageTransition';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
// event
const Delhievent =React.lazy(() => import('./views/event/event'))
// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
// const Forgot = React.lazy(() => import('./views/pages/forgot/Forgot'))
const Login = React.lazy(() => import('./views/pages/login/Login'))

const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const Pageindex = React.lazy(() => import('./views/pages/index/index'))
const Articlefeeddetails = React.lazy(() => import('./views/pages/index/articlefeeddetails'))
const CIOSpeakdetails = React.lazy(() => import('./views/pages/index/CioTalkDetails'))
const EmergingProduct = React.lazy(() => import('./views/pages/index/emerging'))
const Aboutus = React.lazy(() => import('./views/pages/About/About'))
const Technology = React.lazy(() => import('./views/pages/Technology/Technology'))
const TechnologyDetails = React.lazy(() => import('./views/pages/Technology/techoDetails'))
const ChapterDetails = React.lazy(() => import('./views/pages/index/ChapterDetails'))
const Team = React.lazy(() => import('./views/pages/team/Team'))
const Membership = React.lazy(() => import('./views/membership/Membership'))
const Award = React.lazy(() => import('./views/award/Award'))
const Blog = React.lazy(() => import('./views/blog/blog'))
const Pages = React.lazy(() => import('./views/blog/Page'))
const Contactus = React.lazy(() => import('./views/contact/contact'))
const Faq = React.lazy(() => import('./views/FAQ/faquation'))
const EventCio = React.lazy(() => import('./views/events/upcomingevent'))
const PastEvent = React.lazy(() => import('./views/events/pastevent'))
const Upcomingeventdetails = React.lazy(() => import('./views/events/upcomingdetails'))
const Pasteventdetails = React.lazy(() => import('./views/events/Pasteventdetails'))
const Galary = React.lazy(() => import('./views/events/galary'))
const Galaryimages = React.lazy(() => import('./views/events/galaryimages'))
const Disclaimer = React.lazy(() => import('./views/pages/disclaimer/disclaimer'))
const Terms = React.lazy(() => import('./views/pages/termscondition/terms'))
const Membership_Terms = React.lazy(() => import('./views/pages/termscondition/membership_terms'))
const Notice = React.lazy(() => import('./views/pages/termscondition/notice'))
const Policy = React.lazy(() => import('./views/pages/privacy_policy/Policy'))
const Signup = React.lazy(() => import('./roles/superadmin/signup/signupform'))
const Registerform = React.lazy(() => import('./roles/superadmin/signup/registerform'))
const Resetpassword = React.lazy(() => import('./views/Login/resetpassword'))
const Forgotpassword = React.lazy(() => import('./views/Login/forgotpassword'))
const Logincio = React.lazy(() => import('./views/Login/logincio'))
const CioInterview = React.lazy(() => import('./views/pages/index/ciointerview'))
const Pasteventphoto =React.lazy(() => import('./views/events/pasteventphoto'))
const Scanner = React.lazy(() => import('./guestlogin/scanner'))
const Scurity = React.lazy(() => import('./scurity'))

// mobile pages

const About_Mobile = React.lazy(() => import('./mobilepages/aboutusM/about_mobile'))
const Membership_Terms_Mobile = React.lazy(() => import('./mobilepages/membershipterms/membership_term_mobile'))
const FAQ_Mobile = React.lazy(() => import('./mobilepages/faq_mobile/faq_mobile'))
const Terms_Mobile = React.lazy(() => import('./mobilepages/terms_mobile/terms_mobile'))



class App extends Component {

  render() {
    let base = '/'
    let uri = 'about/'
    let encoded = window.btoa(uri)

    const date = new Date()
    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()
    let currentDate = `${day}-${month}-${year}`
    let base1 = '/'
    let uri1 = `signupform/${currentDate}`
    let encoded1 = window.btoa(uri1)

    let base2 = '/'
    let uri2 = 'team/'
    let encoded2 = window.btoa(uri2)

    let base3 = '/'
    let uri3 = 'membership/'
    let encoded3 = window.btoa(uri3)

    let base4 = '/'
    let uri4 = 'contact/'
    let encoded4 = window.btoa(uri4)

    let base5 = '/'
    let uri5 = 'faquation/'
    let encoded5 = window.btoa(uri5)

    let base6 = '/'
    let uri6 = 'upcomingevent/'
    let encoded6 = window.btoa(uri6)

    let base7 = '/'
    let uri7 = 'pastevent/'
    let encoded7 = window.btoa(uri7)

    let base8 = '/'
    let uri8 = 'galary/'
    let encoded8 = window.btoa(uri8)

    let base9 = '/'
    let uri9 = 'eventdetails'
    let encoded9 = window.btoa(uri9)
    console.log('base4:', base9 + encoded9)

    let base10 = '/'
    let uri10 = 'pasteventdetails'
    let encoded10 = window.btoa(uri10)

    let base11 = '/'
    let uri11 = 'galaryimages'
    let encoded11 = window.btoa(uri11)

    let base12 = '/'
    let uri12 = 'technology/'
    let encoded12 = window.btoa(uri12)

    let base13 = '/'
    let uri13 = 'tech_details'
    let encoded13 = window.btoa(uri13)

    let base14 = '/'
    let uri14 = 'blog/'
    let encoded14 = window.btoa(uri14)

    let base15 = '/'
    let uri15 = 'pages'
    let encoded15 = window.btoa(uri15)

    let base16 = '/'
    let uri16 = 'login/'
    let encoded16 = window.btoa(uri16)

    let base17 = '/'
    let uri17 = 'registerform/'
    let encoded17 = window.btoa(uri17)

    let base18 = '/'
    let uri18 = 'articlefeeds_detail'
    let encoded18 = window.btoa(uri18)

    let base19 = '/'
    let uri19 = 'ciospeak_details'
    let encoded19 = window.btoa(uri19)

    let base20 = '/'
    let uri20 = 'emergingProduct-details'
    let encoded20 = window.btoa(uri20)

    let base21 = '/'
    let uri21 = 'interviews'
    let encoded21 = window.btoa(uri21)

    let base22 = '/'
    let uri22 = 'chapter_details'
    let encoded22 = window.btoa(uri22)

    let base99 = '/'
    let uri99 = '/pasteventphoto'
    let encoded99 = window.btoa(uri99)

    const {location} = this.props

    return (
      <HashRouter>

        <PageTransition location={location}>
        <Suspense fallback={loading}>
        <Scurity />
          <AuthProvider>
            <Routes>
            <Route path="/scanner" name="Home" element={<Scanner />} />


              <Route exact path="/login" name="Login Page" element={<AuthprivateRoute><Login /></AuthprivateRoute>} />
              <Route exact path="/guestlogin" name="Login Page" element={<AuthprivateRoute><Logincio /></AuthprivateRoute>} />

              <Route exact path="/404" name="Page 404" element={<Page404 />} />
              <Route exact path="/500" name="Page 500" element={<Page500 />} />
              <Route
                path="*"
                name="dash"
                element={
                  <PrivateRoute>
                    <DefaultLayout />
                  </PrivateRoute>
                }
              />
              <Route path="/" name="Home" element={<HomePrivateRoute><Pageindex /></HomePrivateRoute>} />
              <Route
                path={base18 + encoded18 + '/:id'}
                name="Articlefeeddetails"
                element={<Articlefeeddetails />}
              />
              <Route
                path={base19 + encoded19 + '/:id'}
                name="CIOSpeakdetails"
                element={<CIOSpeakdetails />}
              />
              <Route
                path={base20 + encoded20 + '/:id'}
                name="EmergingProduct"
                element={<EmergingProduct />}
              />
              <Route path={base + encoded} name="About" element={<Aboutus />} />
              <Route path={base12 + encoded12} name="Technology" element={<Technology />} />
              <Route
                path={base13 + encoded13 + '/:id'}
                name="TechnologyDetails"
                element={<TechnologyDetails />}
              />
              <Route
                path={base22 + encoded22 + '/:id'}
                name="ChapterDetails"
                element={<ChapterDetails />}
              />
              <Route path={base2 + encoded2} name="team" element={<Team />} />
              <Route path={base3 + encoded3} name="Membership" element={<Membership />} />
              <Route path="/award" name="Award" element={<Award />} />
              <Route path={base14 + encoded14} name="Blog" element={<Blog />} />
              <Route path={base15 + encoded15 + '/:id'} name="Pages" element={<Pages />} />
              <Route path={base4 + encoded4} name="Contactus" element={<Contactus />} />
              <Route path={base5 + encoded5} name="Faq" element={<Faq />} />
              <Route path={base6 + encoded6} name="EventCio" element={<EventCio />} />
              <Route path={base7 + encoded7} name="PastEvent" element={<PastEvent />} />
              <Route
                path={base9 + encoded9 + '/:id'}
                name="Upcomingeventdetails"
                element={<Upcomingeventdetails />}
              />
              <Route
                path={base10 + encoded10 + '/:id'}
                name="Pasteventdetails"
                element={<Pasteventdetails />}
              />
              <Route path={base8 + encoded8} name="Galary" element={<Galary />} />
              <Route
                path={base11 + encoded11 + '/:id'}
                name="Galaryimages"
                element={<Galaryimages />}
              />
              <Route path={ base99 + encoded99+ '/:id'} name ='Pasteventphoto' element={ <Pasteventphoto/>} />
              <Route path="/disclaimer" name="Disclaimer" element={<Disclaimer />} />
              <Route path="/terms" name="Terms" element={<Terms />} />
              <Route path="/notice" name="Notice" element={<Notice />} />
              <Route
                path="/membership_terms"
                name="Membership_Terms"
                element={<Membership_Terms />}
              />
              <Route path="/policy" name="Policy" element={<Policy />} />
              <Route path={base1 + encoded1 + '/:id'} name="Signup" element={<Signup />} />
              <Route path="/registration" name="Registerform" element={<Registerform />} />
              <Route path="/resetpassword/:uid/:token" name="Resetpassword" element={<Resetpassword />} />
              <Route path="/forgotpassword" name="Forgotpassword" element={<Forgotpassword />} />
              <Route
                path={base21 + encoded21 + '/:id'}
                name="CioInterview"
                element={<CioInterview />}
              />


              {/* mobile pages */}
              <Route path="/aboutus_m" name="About_Mobile" element={<About_Mobile />} />
              <Route path="/membershipterm_m" name="Membership_Terms_Mobile" element={<Membership_Terms_Mobile />} />
              <Route path="/faq_m" name="FAQ_Mobile" element={<FAQ_Mobile />} />
              <Route path="/termscondition_m" name="Terms_Mobile" element={<Terms_Mobile />} />
            </Routes>
          </AuthProvider>
        </Suspense>
        <ScrollToTopArrow />
        </PageTransition>
      </HashRouter>
    )
  }
}

export default App
