import { createContext, useState, useEffect } from 'react'
import jwt_decode from 'jwt-decode'
import { useNavigate } from 'react-router-dom'
import baseurl from 'src/utils/baseurl'
import crypto from 'crypto-js';
import apiKey from "src/utils/apikey"

const baseURL = baseurl()
const AuthContext = createContext()
const currentYear = new Date().getFullYear()

export default AuthContext

let base1 = '/'
let uri1 = 'dashboard/'
let encoded1 = window.btoa(uri1)

let base69 = '/'
let uri69 = 'chapteradmin/profileupdate/'
let encoded69 = window.btoa(uri69)

let base65 = '/'
let uri65 = 'chapteradmin/Cmembership/'
let encoded65 = window.btoa(uri65)

export const AuthProvider = ({ children }) => {
  const apikey = apiKey()
  const hash = crypto.SHA256(apikey).toString();

  let [authTokens, setAuthTokens] = useState(() =>
    sessionStorage.getItem('authTokens') ? JSON.parse(sessionStorage.getItem('authTokens')) : null,
  )
  let [user, setUser] = useState(() =>
    sessionStorage.getItem('authTokens') ? jwt_decode(sessionStorage.getItem('authTokens')) : null,
  )
  let [loading, setLoading] = useState(true)
  let [modules_no, setModules_no] = useState([])
  let [sub_modules_no, setSub_modules_no] = useState([])
  let [permission_no, setPermission_no] = useState([])

  const history = useNavigate()

  let loginUser = async (e) => {
    e.preventDefault()
    let response = await fetch(baseURL + '/api/login/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json', 'x-api-key': apikey
      },
      body: JSON.stringify({ email: e.target.email.value, password: e.target.password.value }),
    })
    let data1 = await response.json()
    let data = data1.result
    console.log('data1', data1)
    if (data1.success === 0) {
      alert(data1.message)
    } else if (response.status === 200) {
      if (data1.result.user.is_active != "1" || data1.result.user.reject == 1 || data1.result.user.long_reject == 1 || data1.result.user.current_membership_status == 4 || data1.result.user.current_membership_status == 8) {
        alert("Your account is not active So please contact CIO Klub")
        history(base1)
      } else {
        setAuthTokens(data)
        setUser(jwt_decode(data.access))
        sessionStorage.setItem('authTokens', JSON.stringify(data))
        let response = await fetch(baseURL + '/api/1/userHistory/', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${data.access}`,
            'Content-Type': 'application/json',
            'x-api-key': apikey
          },
          body: JSON.stringify({
            user_id: data1.result.user.id,
            log: 'Login Successfully',
            chapter: data1.result.user.location_id,
            message: 'Login by ' + data1.result.user.name,
            updated_by: data1.result.user.name,
          }),
        })
        // let response2 = await fetch(baseURL + `/loyalty/loyaltybyactivity/Login/`, {
        //   method: 'GET',
        //   headers: {
        //     Authorization: `Bearer ${data.access}`,
        //     'Content-Type': 'application/json', 'x-api-key': apikey,
        //   },
        // })
        // let Activity = await response2.json()
        // let activitypt = Activity.result[0]?.point_for_all_activities
        //   ? Activity.result[0].point_for_all_activities
        //   : 0
        // console.log(activitypt)
        // let response3 = await fetch(
        //   baseURL +
        //   `/loyalty/loyaltybyuserandlogin/${data1.result.user.id}/Login/${new Date()
        //     .toISOString()
        //     .slice(0, 10)}/`,
        //   {
        //     method: 'GET',
        //     headers: {
        //       Authorization: `Bearer ${data.access}`,
        //       'Content-Type': 'application/json', 'x-api-key': apikey,
        //     },
        //   },
        // )
        // let currentloyaltypt = await response3.json()
        // console.log(currentloyaltypt.result[0])
        // if (!currentloyaltypt.result[0]) {
        //   let response3 = await fetch(baseURL + '/loyalty/genearted_loyalty_point/', {
        //     method: 'POST',
        //     headers: {
        //       Authorization: `Bearer ${data.access}`,
        //       'Content-Type': 'application/json', 'x-api-key': apikey,
        //     },
        //     body: JSON.stringify({
        //       user_id: data1.result.user.id,
        //       activity: 'Login',
        //       point_for_activity: activitypt,
        //     }),
        //   })
        //   if (data1.result.user.role == 'superadmin' ||
        //     data1.result.user.role == 'nationalpresident' ||
        //     data1.result.user.role == 'governingbody' ||
        //     data1.result.user.role == 'supersuperadmin') {
        //     history(base1 + encoded1, { state: 'Congratulation you get point for daily login' })
        //   } else if (data1.result.user.role == 'chapterpresident' ||
        //     data1.result.user.role == 'chapteradmin' ||
        //     data1.result.user.role == 'contentmanager' ||
        //     data1.result.user.role == 'member') {
        //     if ((data1.result.user.current_membership_status == 6 || data1.result.user.current_membership_status == 2)) {
        //       history(base65 + encoded65)
        //     } else {
        //       history(base69 + encoded69)
        //     }
        //   }
        // } else {
          if (data1.result.user.role == 'superadmin' ||
            data1.result.user.role == 'nationalpresident' ||
            data1.result.user.role == 'governingbody' ||
            data1.result.user.role == 'supersuperadmin'
          ) {
            history(base1 + encoded1)
          } else if (data1.result.user.role == 'chapterpresident' ||
            data1.result.user.role == 'chapteradmin' ||
            data1.result.user.role == 'contentmanager' || data1.result.user.role == 'member') {
            if ((data1.result.user.current_membership_status == 6 || data1.result.user.current_membership_status == 2)) {
              history(base65 + encoded65)
            } else {
              history(base69 + encoded69)
            }
          // }
        }
      }
    } else {
      alert('Something went wrong!')
    }
  }

  let base16 = '/'
  let uri16 = 'login/'
  let encoded16 = window.btoa(uri16)

  let logoutUser = () => {
    setAuthTokens(null)
    setUser(null)
    sessionStorage.removeItem('authTokens')
    history('/')
    // window.location.reload();
  }

  let contextData = {
    user: user,
    authTokens: authTokens,
    modules_no: modules_no,
    sub_modules_no: sub_modules_no,
    permission_no: permission_no,
    setAuthTokens: setAuthTokens,
    setUser: setUser,
    setModules_no: setModules_no,
    setSub_modules_no: setSub_modules_no,
    setPermission_no: setPermission_no,
    loginUser: loginUser,
    logoutUser: logoutUser,
  }

  useEffect(() => {
    if (authTokens) {
      setUser(jwt_decode(authTokens.access))
    }
    setLoading(false)
    // getcreate_project()
    // console.log(user)
  }, [authTokens, loading])

  return (
    <AuthContext.Provider value={contextData}>{loading ? null : children}</AuthContext.Provider>
  )
}
