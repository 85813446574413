import { Navigate } from 'react-router-dom'
import { useContext } from 'react'
import AuthContext from 'src/context/AuthContext'


let base1 = '/'
let uri1 = 'dashboard/'
let encoded1 = window.btoa(uri1)

const AuthPrivateRoute = ({children}) => {
    const {user } = useContext(AuthContext)
    // const auth = useAuth();
    return !user? children : <Navigate to={base1 + encoded1} />
}

export default AuthPrivateRoute;